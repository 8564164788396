import {
  isEmailCommon,
  isNotChinese,
  isFormatNumStrChinese,
  isPostCodeRules,
  isPasswordRules
} from "kits"

import getCachedPromise from "@/utils/getCachedPromise"
import { customerInteractor } from "~/core"
import { useGlobalStore } from "~/stores";


/**
 * 通用字段校验
 * @param {v} value
 * @param {required} true: 必填
 * @param {field} 字段名称
 * @param {min} 最少输入几位
 */

const Default = (v, required, field, min) => {
  if (v) {
    if (min && min > v.length) {
      return `${field} needs to be at least ${min} characters`
    } else {
      return true
    }
  } else {
    if (required) {
      return `${field} is required`
    }
  }
}

/**
 * 邮箱 已替换kits
 * @param {v} value
 * @param {required} true: 必填
 */
const Email = async (v, required, notCheckDns=false) => {
  const res = await getCachedPromise("registerMailRule")
  if (v) {
    if (
      !isEmailCommon(
        v,
        res
      )
    ) {
      return "Incorrect Email Address"
    } else {
      if (notCheckDns) return true
      const gStore = useGlobalStore()
      const [result] = await customerInteractor.registerMailDnsCheckApi(v)
      if (!result) {
        gStore.setDNSValid(false)
        return "Incorrect Email Address"
      }else {
        gStore.setDNSValid(true)
        return true
      }
    }
  } else {
    if (required) {
      return "Email is required"
    }
  }
}

/**
 * 验证码
 * @param {v} value
 * @param {required} true: 必填
 */
const VerifyCode = (v, required) => {
  if (v) {
    return true
  } else {
    if (required) {
      return "Verification Code is required"
    }
  }
}

/**
 * 密码
 * @param {v} value
 * @param {required} true: 必填
 *
 */
const Password = (v, required, disableBol) => {
  if (v) {
    if ((disableBol && v.includes("%")) || (disableBol && v.includes("+"))) {
      return "Password cannot contain % or +"
    } else if (!isPasswordRules(v)) return false
  } else {
    if (required) {
      return "Password is required"
    }
  }
}

/**
 * 确认密码
 * @param {v} value
 * @param {required} true: 必填
 * @param {password} 密码
 */
const ConfirmPassword = (v, required, password) => {
  if (v) {
    if (v != password && password) {
      return "Entered passwords differ"
    } else {
      return true
    }
  } else {
    if (required) {
      return "Confirm Password is required"
    }
  }
}

/**
 * 禁止汉字 已替换kits
 * @param {v} value
 * @param {required} true: 必填
 * @param {field} 字段名称
 */
const NoHanzi = (v, required, field) => {
  if (v) {
    if (!isNotChinese(v)) return `Must be a valid ${field}`
  } else {
    if (required) {
      return `${field || "Password"} is required`
    }
  }
}

/**
 * 只允许输入英文 已替换kits
 * @param {v} value
 * @param {required} true: 必填
 * @param {field} 字段名称
 */
const EnglishName = (v, required, field) => {
  if (v) {
    if (!isFormatNumStrChinese(v, "22")) return `Must be a valid ${field}`
  } else {
    if (required) {
      return `${field || "Password"} is required`
    }
  }
}

/**
 * @param {v} value
 * @param {required} true: 必填
 */
const PostCode = (v, required) => {
  if (v) {
    if (!isPostCodeRules(v)) return "Must be a valid Zip Code"
  } else {
    if (required) {
      return "Zip Code is required"
    }
  }
}

/**
 * 截至日期
 * @param {v} value
 * @param {required} true: 必填
 */
const Expiration = (v, required) => {
  if (v) {
    if (v.length === 5) {
      const expDate = v.split("/")
      const date = new Date()
      const realDate = [
        date.getMonth() + 1,
        Number(String(date.getFullYear()).substring(2, 4))
      ]

      if (
        (realDate[0] > Number(expDate[0]) &&
          Number(expDate[1]) === realDate[1]) ||
        realDate[1] > Number(expDate[1])
      ) {
        return "Your credit card has expired, please check!"
      }
    }
  } else {
    if (required) {
      return "Expiration is required"
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      validate: {
        Default,
        Email,
        VerifyCode,
        Password,
        ConfirmPassword,
        NoHanzi,
        EnglishName,
        PostCode,
        Expiration
      }
    }
  }
})
